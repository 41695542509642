@import '../../index.scss';

.app_header {
  width: 100%;
  height: auto;
  background: $white;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  color: $blue;
  font-family: $font-primary;

  @media screen and (max-width: 1108px) {
    display: flex;
    justify-content: center;
    // flex-direction: column;
    align-items: center;
  }
}

.app_header_banner {
  margin-top: 150px;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
  padding: 30px;

  .tag {
    font-family: $font-alt;
    padding-bottom: 100px;
    text-transform: capitalize;
    position: relative;
    font-size: 72px;
    font-weight: normal;
  }

  // .app_header_banner_body {
  //   display: flex;
  //   flex-direction: column;
  //   justify-content: center;

  //   .middle {
  //     color: $teal;
  //   }
    
  //   h3 {
  //     font-size: 84px;
  //     font-weight: normal;
  //   }

  //   p {
  //     padding-top: 50px;
  //     font-size: 56px;
  //     font-family: $font-alt;
  //   }
  // }

  .app_header_banner_invite {
    width: 150px;
    height: 70px;
    border-radius: 5px;
    background: $aqua;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: $black;
    font-family: $font-alt;

    h4 {
      font-size: 18px;
      font-weight: 300;
    }

    a {
      text-decoration: none;
      color: $white;

    }
    a:hover {
      color: $grey;
      transition: 0.2s;
    }
  }

  @media screen and (max-width: 1108px) {
    margin-top: 0;
  }
}

.app_header_banner-img {
  img {
    width: 500px;
    height: 500px;

    @media screen and (max-width: 1500px) {
      width: 400px;
      height: 400px;
    }

    @media screen and (max-width: 1108px) {
      width: 600px;
      height: 600px;
    }

    @media screen and (max-width: 700px) {
      width: 400px;
      height: 400px;
    }

    @media screen and (max-width: 450px) {
      width: 200px;
      height: 200px;
    }
  }
}

.app_header_img {
  margin-top: 200px;
  padding-right: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: auto;

  .header_img {
    width: 900px;
    height: 600px;
    z-index: 1;
    border-radius: 10px;

    @media screen and (max-width: 1500px) {
      width: 600px;
      height: 400px;
    }

    @media screen and (max-width: 800px) {
      width: 400px;
      height: 300px;
    }

    @media screen and (max-width: 550px) {
      width: 300px;
      height: 200px;
    }
  }

  @media screen and (max-width: 1108px) {
    margin-top: 50px;
    padding-right: 0;
  }
}

.app_header_comment {
  display: flex;
  position: absolute;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: rgba(255, 255, 255, 0.25);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px); /****webkit because this feature is not available on all browsers****/
  border: 1px solid rgba(255, 255, 255, 0.18);
  z-index: 3;
  width: 300px;
  border-radius: 5px;
  margin-left: 100px;
  margin-top: 75px;

  img {
    width: 70px;
    height: 70px;
    padding-right: 15px;
  }

  .percent {
    display: flex;
    flex-direction: column;
  }

  .percent_bubble {
    display: flex;
    flex-direction: row;
  }
}



.app_header_msg {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgba(255, 255, 255, 0.25);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px); /****webkit because this feature is not available on all browsers****/
  border: 1px solid rgba(255, 255, 255, 0.18);
  z-index: 2;
  margin-left: 25%;
  margin-bottom: 30%;
  border-radius: 5px;

  h3 {
    font-weight: normal;
    width: 100px;
    padding: 5px;
  }
}

.app_employment_invite {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
  padding: 100px;
  font-size: 72px;
  background-color: $blue;

  .top {
    font-weight: 400;
    color: $white;
  }

  .middle {
    font-weight: 400;
    color: $teal;
  }

  @media screen and (max-width: 1000px) {
    width: auto;
    font-size: 42px;
    padding: 20px;
  }
}

.employment_banner {
  width: 100%;
  height: 200px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .employment-text {
    h2 {
      font-family: $font-alt;
      font-size: 28px;
      font-weight: normal;
      padding: 20px;
    }
  }

  .employment-arrow {
    svg {
      width: 100px;
      height: 75px;
      color: $teal;
  
      @media screen and (max-width: 650px) {
        margin-left: 140px;
      }
    }
  
    svg:hover {
      color: $grey;
      transition: 0.3s;
    }
  }
}