@import '../../index.scss';

.app_services {
    width: 100%;
    height: 100%;
    background: $aqua;
    padding-bottom: 50px;

    .app_services_banner {
        width: 100%;
        height: 200px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center; 
        text-align: center;
        font-family: $font-primary;
        padding-top: 300px;

        // h1 {
        //     color: $black;
        //     font-weight: normal;
        //     font-size: 42px;
        //     line-height: 80px;
        //     // margin-top: 250px;

        //     @media screen and (max-width: 650px) {
        //       font-size: 32px;
        //       margin-top: 0;
        //     }
        // }
        h2 {
          font-family: $font-alt;
          font-size: 64px;
          font-weight: normal;
          color: $blue;
          text-transform: capitalize;
          text-shadow: 0px 8px 10px rgba(0, 0, 0, 0.3);
          padding-top: 100px;

          @media screen and (max-width: 650px) {
            font-size: 48px;
          }

        // .title_container {
        //   display: flex;
        //   flex-direction: column;
        //   justify-content: center;
        //   align-items: center;
        //   width: 100%;
        //   height: auto;
        //   margin-top: 100px;

        //   }
        }

        .services_tag {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100px;
            height: 40px;
            background: $black;
            border-radius: 5px;
            position: absolute;
            margin-bottom: 50px;

            h3 {
                font-weight: 400;
                color: $white;
            }

        } 
        
        .services_title {
            text-align: center;

            @media screen and (max-width: 850px) {
              margin-top: 600px;
              width: 600px;
            }
            
            @media screen and (max-width: 650px) {
              margin-top: 100px;
              width: 300px;
            }
        }

        img {
          width: 200px;
          height: 200px;
          margin-top: 50px;
        }
      
      @media screen and (max-width: 650px) {
        justify-content: space-between;
      }

    }

    .app_services_container { 
        width: 100%;
        margin-top: 15%;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 10px; /* Adjust the gap between grid items if needed */
        justify-items: center;
        padding: 50px;


        .service_item {
            width: 300px;
            height: 400px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background: $white;
            border-radius: 5px;
            font-family: $font-alt;
            text-align: center;
            padding: 10px;
            margin-top: 20px;
            box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.3);
            // border: 1px solid grey;

            
            h3 {
                font-weight: normal;
                // letter-spacing: 1px;
                font-weight: bold;
                font-size: 20px;
                color: $black;
                margin-top: 10px;
            }
            
            p {
                margin-top: 10px;
                font-weight: normal;
                // letter-spacing: 1px;
                font-weight: normal;
                font-size: 15px;
                color: $blue;
            }

            ul {
                line-height: 10px;
                list-style: none;
                margin-top: 20px;
                color: $black;
                // letter-spacing: 1px;
            }

            .service_icon {
                width: 180px;
                height: 180px;
                border-radius: 50%;
                background: $white;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .schedule_button {
              width: 200px;
              height: 40px;
              background: $black;
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: row;
              border-radius: 5px;
              // margin-top: 50px;
      
              a {
                text-decoration: none;
                color: white;
                font-family: $font-primary;
                font-weight: 400;
              }
              
              a:hover {
                transition: 1s;
                color: $grey;
              }
      
              svg {
                margin-left: 20px;
                color: white;
      
                :hover {
                  transition: 1s; 
                  color: $grey;
                }
              }
            }
  
            @media screen and (max-width: 850px) {
              margin-right: 0;
              justify-content: flex-start;
              // margin-left: 150px;
            }

            @media screen and (max-width: 750px) {
              // width: 500px;
              // margin-left: 120px;
              margin-right: 0;
            }
            
            @media screen and (max-width: 650px) {
              width: 500px;
              margin-left: 0;
              margin-right: 0;
            }
            
            @media screen and (max-width: 550px) {
              width: 500px;
              margin-left: 0;
              margin-right: 0;
            }

            @media screen and (max-width: 450px) {
              width: 300px;
              margin-left: 0;
              margin-right: 0;
            }

          }

      @media screen and (max-width: 1400px) {
        margin-top: 500px;
      }
      @media screen and (max-width: 1300px) {
        grid-template-columns: repeat(3, 1fr);
      }
      
      @media screen and (max-width: 992px) {
        grid-template-columns: repeat(2, 1fr);
      }
      
      // @media screen and (max-width: 850px) {
      //   grid-template-columns: repeat(1, 1fr);
      //   // margin-top: 950px;
      //   // margin-right: 400px;
      //   // justify-content: flex-start;
      //   // margin-left: 0;
      // }
      
      @media screen and (max-width: 650px) {
        grid-template-columns: repeat(1, 1fr);
      }
      @media screen and (max-width: 550px) {
        margin-right: 0;
        padding: 0;
        // margin-left: 20px;
      }
    }

    // @media screen and (max-width: 850px) {
    //   height: 120vh;
    // }
    
    // @media screen and (max-width: 650px) {
    //   height: 100vh;
    // }
    
}