.app_headerOverlay {
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  flex-direction: column;
  position: relative;
}

.app_headerOverlay-black {
  height: 25%;
  background: black;
}

.app_headerOverlay-img {
  height: 75%;
}