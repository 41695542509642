@import '../../index.scss';

.app_contact_page {
  width: 100%;
  height: 100%;
}

.app_contact_tel {
  width: auto;
  padding: 50px;

  a {
    text-decoration: none;
    color: $teal;
    font-size: 42px;
    font-family: $font-alt;
  }

  a:hover {
    color: $aqua;
    transition: 0.5s;
  }
}