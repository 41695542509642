@import '../../index.scss';

.app_why {
  width: 100%;
  height: 100%;
  background: $white;
  position: relative;
  padding-top: 50px;
}

.app_why_header_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: $white;
}

.app_why_title {
  font-family: $font-alt;
  font-size: 72px;
  font-weight: normal;
  padding-top: 100px;
  letter-spacing: 3px;
  text-transform: capitalize;
}

.app_why_header {
  width: 100%;
  height: 100%;
  background: $white;
  display: flex;
  justify-content: space-evenly;

  .app_accolades {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    font-family: $font-primary;
    color: black;
    width: 130px;
    height: 100px;
    padding: 10px;
    background: $grey;
    border-radius: 5px;
    line-height: 25px;

    h3 {
      color: $orange;

      @media screen and (max-width: 650px) {
        font-size: 18px;
      }
    }
    
    p {
      @media screen and (max-width: 650px) {
        font-size: 14px;
      }
    }

    @media screen and (max-width: 650px) {
      justify-content: flex-start;
    }
  }

  @media screen and (max-width: 650px) {
    flex-wrap: wrap;
    justify-content: flex-start;
    padding-left: 20px;
  }
}



.app_why_body {
  display: flex;
  justify-content: space-around;
  flex-direction: row-reverse;
  width: 100%;
  height: 100%;
  background: $white;
  padding-bottom: 100px;
  flex-wrap: wrap;

  .img_tag {
    font-family: $font-primary;
    font-size: 20px;
    margin-top: 20px;

    a {
      text-decoration: none;
      color: $teal;
    }

    a:hover {
      color: $aqua;
      transition: 0.5s;
    }
  }

  img {
    width: 500px;
    height: 700px;
    margin-top: 100px;
    border-radius: 10px;
    z-index: 1;

    @media screen and (max-width: 1100px) {
      width: 700px;
      height: 600px;
      margin-left: 0;
    }
    
    @media screen and (max-width: 850px) {
      width: 375px;
      height: 275px;
      margin-left: 0;
    }
    
    @media screen and (max-width: 650px) {
      margin-left: 35px;
    }
    
    @media screen and (max-width: 450px) {
      width: 275px;
      height: 175px;
      margin-left: 40px;
    }
  }

  @media screen and (max-width: 650px) {
    justify-content: flex-start;
    padding-left: 20px;
  }

  .app_why_container {
    width: 600px;
    height: 720px;
    z-index: 2;
    background: $white;
    border-radius: 5px;
    margin-left: 20px;
    margin-top: 50px;

    .app_why_content {
      display: flex;
      flex-direction: column;
      padding: 100px;
      
      .content_tag {
        width: 100px;
        height: 35px;
        margin-bottom: 50px;
        background: $black;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;

        h3 {
          font-family: $font-primary;
          color: $white;
          font-weight: 500;
        }
      }

      .content_title {
        font-family: $font-primary;
        color: black;
        font-weight: 400;
        margin-bottom: 50px;
        line-height: 30px;

        @media screen and (max-width: 1600px) {
          width: 80%;
          margin-left: 32%;
        }
        
        @media screen and (max-width: 650px) {
          width: 300px;
        }
      }

      .tag_header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 50px;
        font-family: $font-primary;
        line-height: 30px;

        h4 {
            color: $orange;
            font-weight: 400;
        }

        @media screen and (max-width: 1600px) {
          align-items: center;
        }
        
        @media screen and (max-width: 650px) {
          width: 300px;
        }
      }

      .body_content {
        font-family: $font-primary;
        color: black;
        font-weight: 300;

        @media screen and (max-width: 1600px) {
          font-size: 24px;
        }
        
        @media screen and (max-width: 650px) {
          width: 300px;
          line-height: 25px;
        }
      }

      .schedule_button {
        width: 200px;
        height: 40px;
        background: $black;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        border-radius: 5px;
        margin-top: 50px;

        a {
          text-decoration: none;
          color: $white;
          font-family: $font-primary;
          font-weight: 400;
        }
        
        a:hover {
          transition: 1s;
          color: $grey;
        }

        svg {
          margin-left: 5px;
          color: $white;

          :hover {
            transition: 1s;
            color: $grey;
          }
        }
      }

      @media screen and (max-width: 1600px) {
        justify-content: center;
        align-items: center;

      }

      @media screen and (max-width: 650px) {
        align-items: center;
        padding: 0;
        padding-top: 100px;
        padding-left: 0;
      }
    }
    
    @media screen and (max-width: 1600px) {
      position: relative;
      width: 100%;
    }
    
    @media screen and (max-width: 650px) {
      margin-left: 0;
      width: 100%;
      line-height: 20px;
      height: auto;
    }
  }
}
