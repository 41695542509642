@import '../../index.scss';

.about_page_container {
  width: 100%;
  height: auto;
  padding-top: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.about_page_title {
  padding-bottom: 100px;
}

.title {
  font-family: $font-alt;
  font-size: 64px;
  font-weight: 400;
}

.subtitle {
  font-family: $font-alt;
  font-size: 48px;
  font-weight: 300;
  font-style: italic;
}

.about_page_content {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-evenly;
  width: 100%;
  flex-wrap: wrap;

  img {
    width: 500px;
    height: 800px;
    border-radius: 5px;
  }

  .about_textbox {
    width: 40%;
    padding: 10px;

    ul {
       list-style: none;
       font-family: $font-primary;
       line-height: 25px;
    }

    li {
      padding-bottom: 20px;
    }

    @media screen and (max-width: 1000px) {
      width: 100%;
      padding: 100px;
    }
  }
}

.app_about_scholarship {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 100px;

  @media screen and (max-width: 1000px) {
    padding: 30px;
  }
}

.app_about_scholarship-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 75px;

  h4 {
    font-size: 72px !important;
  }
}

.app_about_scholarship-body {
  width: 40%;
  padding: 10px;

  ul {
     list-style: none;
     font-family: $font-primary;
     line-height: 25px;
  }

  li {
    padding-bottom: 20px;
  }

  a {
    font-size: 24px;
    font-weight: 700;
    text-decoration: none;
    color: $aqua;
  }

  a:hover {
    color: $grey;
    transition: 0.5s;
  }

  @media screen and (max-width: 1000px) {
    width: 100%;
    padding: 100px;
  }
}

.about_credentials {
  display: flex;
  justify-content: space-around;
  width: 500px;
  padding-top: 100px;
  padding-bottom: 100px;
  margin-right: 50px;
  background: $off-white;
  border-radius: 5px;

  h2 {
    font-family: $font-alt;
    font-size: 42px;
    padding-top: 10px;
    font-weight: normal;
  }

  p {
    font-family: $font-primary;
    padding-top: 5px;
  }

  div {
    width: 200px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}