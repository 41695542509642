@import '../../index.scss';

.app_wrap {
  position: relative;
  background-image: url('../../assets/feedback.jpg');
  background-repeat: no-repeat;
  -webkit-background-size: 100%;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  overflow: auto;
  width: 100%;
  height: 100vh;
  min-height: 100%;
  display: flex;
  justify-content: center;

  @media screen and (max-width: 650px) {
    overflow-x: hidden;
  }
}

.app_feedback {
  width: 75%;
  height: 100vh;
  margin-top: 200px;
}

// For the actual Feedback section
.app_feedback_header_container {
  // background: rgba($black, 0.4);
  font-family: $font-primary;
  height: 200px;
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 10px;
  line-height: 45px;
  border-radius: 20px;

  @media screen and (max-width: 650px) {
    width: 330px;
  }

  .app_feedback_subheader {
    color: $white;
    font-size: 18px;
    text-transform: uppercase;
    letter-spacing: 5px;
  }

  .app_feedback_header {
    color: $black;
    font-size: 96px;
    font-weight: normal;
    font-family: $font-alt;

    @media screen and (max-width: 750px) {
      font-size: 64px;
    }

    @media screen and (max-width: 500px) {
      font-size: 48px;
    }
  }
}
.app_feedback_section {
  margin-top: 30px;
  padding-bottom: 14px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.feedback_empty_header {
  color: $black;
  font-size: 36px;
  font-family: $font-alt;

  h1 {
    font-weight: 300;
  }

  @media screen and (max-width: 650px) {
    padding-top: 50px;
    font-size: 24px;
  }
}


// For each individual testimonial
.app_feedback_card {
  width: 80%;
  height: 100%;
  padding: 10px;
  border-radius: 20px;
  background: rgba($grey, 0.5);
  font-family: $font-secondary;

  .quotes {
    color: $white;
    font-size: 48px;
    font-family: $font-secondary;
  }

  .card_body_container {
    margin-top: 3px;

    .card_body_content {
      color: $black;
      font-size: 18px;
      font-family: $font-secondary;
    }

    .card_body_author_container {
      margin-top: 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      font-family: $font-secondary;

      .card_body_author {
        flex: 1 1 0%;
        display: flex;
        flex-direction: column;

        .author {
          color: $black;
          font-size: 16px;

          span {
            color: $aqua;
          }
        }

        .company {
          margin-top: 10px;
          font-size: 14px;
        }
      }

      .card_img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        object-fit: cover;
        margin-left: 50% !important;
      }
    }
  }
  
  @media screen and (max-width: 650px) {
    width: 400px;
  }
}