@import '../../index.scss';

*{
  margin: 0;
  box-sizing: border-box;
}

.app_description {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  background: $white;
  font-family: $font-primary;
  color: white;
  padding-top: 250px;

  @media screen and (max-width: 1000px) {

    // padding-top: 800px;
  }
  
  @media screen and (max-width: 650px) {
  //   padding-top: 1000px;
  }
}

.app_description_header {
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 200px;
  border-radius: 20px;
  text-align: center;

  p {
    font-size: 84px;
    color: $black;
    font-family: $font-alt;

    @media screen and (max-width: 650px) {
      font-size: 20px;
    }
  }

  h2 {
    font-size: 72px;
    font-weight: normal;
    color: $black;
    font-family: $font-alt;

    @media screen and (max-width: 650px) {
      font-size: 36px;
    }
  }

  @media screen and (max-width: 650px) {
      width: 400px;
      height: 100px;
  }
}

.app_description_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  padding: 70px;
  gap: 50px;

  .description_item {
    display: flex;
    justify-content: center;
    flex-direction: row;
    // background: rgba($white, 0.3);
    width: auto;
    border-radius: 20px;
    align-items: center;

    img {
      width: 400px;
      height: 600px;
      border-radius: 5px;

      @media screen and (max-width: 1000px) {
        width: 300px;
        height: 500px;
        // padding-top: 800px;
      }
    }
    
    h3 {
      font-size: 54px;
      // letter-spacing: 2px;
      font-weight: normal;
      padding: 0 0 0 20px;
      color: $blue;
      font-family: $font-alt;

      @media screen and (max-width: 1000px) {
        font-size: 36px;
        padding: 0;
        // padding-top: 800px;
      }
    }

    p {
      font-size: 22px;
      padding: 20px;
      color: $dark-grey;
      letter-spacing: 1px;

      // @media screen and (max-width: 1500px) {
      //   width: 90%;
      //   height: 400px;
      //   overflow: auto;
      // }

      @media screen and (max-width: 1000px) {
        width: 100%;
        height: 400px;
        overflow: auto;
      }
      
      // @media screen and (max-width: 850px) {
      //   width: 350px;
      //   height: 400px;
      //   overflow: auto;
      // }
    }

    @media screen and (max-width: 1000px) {
      width: 100%;
      flex-direction: column;
      text-align: center;
      // padding-top: 800px;
    }
  }

  .text-box {
    @media screen and (max-width: 1000px) {
      width: 100%;
      // padding-top: 800px;
    }
  }

  .description_content {
    width: auto;
  }

  // @media screen and (max-width: 650px) {
  //   margin-right: 150px;
  //   width: 80%;
  // }
} 