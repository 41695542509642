@import '../../index.scss';

*{
  margin: 0;
  box-sizing: border-box;
}

.app__contact {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contact_title-container {
  margin-top: 200px;
}

.contact-title {
  font-size: 64px;
  font-family: $font-alt;
  font-weight: normal;
}

.app_contact_container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: $white;

  @media screen and (max-width: 1000px) {
    flex-direction: column;
    align-items: center;
  }
}

.app_contact_form {
  width: 60%;
  flex-direction: column;
  margin: 1rem 2rem;

  div {
      width: 100%;
      margin: 0.75rem 0;
      border-radius: 10px;
      cursor: pointer;
      background-color: $white;
      transition: all 0.3s ease-in-out;
  

  input, textarea {
      width: 100%;
      padding: 0.95rem;
      border: none;
      border-radius: 7px;
      background-color: $white;
      font-family: $font-alt;
      color: $black;
      outline: none;

  }

  textarea {
      height: 170px;
  }

  &:hover {
      box-shadow: 0 0 10px black;
  }
}

  button {
      padding: 1rem 2rem;
      border-radius: 10px;
      border: none;
      background-color: $grey;
      font-weight: 500;
      color: var(--white-color);
      outline: none;
      margin: 2rem 0 0 0;
      font-family: $font-alt;
      cursor: pointer;
      width: 100%;
  }

  @media screen and (max-width: 1000px) {
    width: 100%;
    margin: 1rem 0;
  }

  // @media screen and (max-width: 1000px) {
  //   flex-direction: column;
  //   align-items: center;
  // }
}