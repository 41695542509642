@import '../../index.scss';

.why_page_container {
  width: 100%;
  height: auto;
  padding-top: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 850px) {
    padding-top: 50px;
    // padding-top: 800px;
  }
}

.why_page_title {
  padding-bottom: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 200px;
  p { 
    font-family: $font-alt;
    font-size: 72px;

    @media screen and (max-width: 1000px) {
      font-size: 36px;
      text-align: center;
    }
  }

  @media screen and (max-width: 1000px) {
    font-size: 36px;
    padding-left: 0;
    // padding-top: 800px;
  }
}

.why_page_content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  padding-bottom: 50px;

  img {
    width: 500px;
    height: 700px;
    border-radius: 5px;

    @media screen and (max-width: 1000px) {
      width: 400px;
      height: 600px;
      // padding-top: 800px;
    }

    @media screen and (max-width: 850px) {
      width: 350px;
      height: 550px;
      // padding-top: 800px;
    }

    @media screen and (max-width: 500px) {
      width: 300px;
      height: 500px;
      // padding-top: 800px;
    }
  }

  .why_textbox {
    width: 50%;
    padding: 10px;

    ul {
      list-style: none;
      font-family: $font-primary;
      line-height: 25px;
    }

    li {
      padding-bottom: 20px;
    }

    .text {
      font-size: 36px;
      font-family: $font-alt;
      padding-bottom: 50px;

      @media screen and (max-width: 850px) {
        font-size: 28px;
      }
    }

    @media screen and (max-width: 1000px) {
      width: 100%;
      text-align: center;
      // padding-top: 800px;
    }
  }

  .why_points {
    width: 70%;
    padding-bottom: 25px;
    list-style: none;

    ul {
      font-family: $font-primary;
      line-height: 25px;
    }

    li {
      padding-bottom: 5px;
      font-size: 18px;
      letter-spacing: 2px;
      font-family: $font-alt;

      @media screen and (max-width: 1000px) {
        width: 100%;
        letter-spacing: 0;
        font-size: 14px;
        // padding-top: 800px;
      }
    }

    li::first-letter {
      font-weight: 700;
      font-size: 24px;
      color: $teal;
    }

    @media screen and (max-width: 1000px) {
      width: 100%;

      // padding-top: 800px;
    }
  }

  @media screen and (max-width: 1000px) {
    flex-direction: column;
  }
}

.why_credentials {
  display: flex;
  justify-content: space-around;
  width: 500px;
  padding-top: 100px;
  padding-bottom: 100px;
  margin-right: 50px;
  background: $off-white;
  border-radius: 5px;

  h2 {
    font-family: $font-alt;
    font-size: 42px;
    padding-top: 10px;
    font-weight: normal;
  }

  p {
    font-family: $font-primary;
    padding-top: 5px;
  }

  div {
    width: 200px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

.why_page_clients {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.clients_title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  
  p {
    font-family: $font-secondary;
    font-size: 18px;
  }
}

.client_container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 40px; 
  justify-items: center;
  border-radius: 20px;
  padding-top: 20px;

  @media screen and (max-width: 1300px) {
    grid-template-columns: repeat(3, 1fr);
  }
  
  @media screen and (max-width: 992px) {
    grid-template-columns: repeat(2, 1fr);
  }
  
  @media screen and (max-width: 650px) {
    grid-template-columns: repeat(1, 1fr);
  }
  @media screen and (max-width: 550px) {
    margin-right: 0;
    // margin-left: 20px;
  }
}

.client_item {
  width: 300px;
  height: 350px;
  padding-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  img {
    width: 210px;
    height: 150px;
  }

  h3 {
    font-family: $font-alt;
  }
}