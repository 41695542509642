@import '../../index.scss';

*{
  margin: 0;
  box-sizing: border-box;
}
.app_footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background: $blue;
  font-family: $font-primary;
}

.app_footer_nav {
  width: 50%;
  display: flex;
  flex-direction: column;

  .logo_container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 400px;
    height: 100px;

    h3 {
      font-family: $font-alt;
      font-weight: normal;
      color: $orange;
      font-size: 36px;
      margin-right: 50px;
    }
    
  }

  .items_container {
    width: 400px;
    height: 200px;
    display: flex;
    justify-content: flex-start;
    margin-left: 50px;

    .nav_items {
      list-style: none;
      padding: 10px;
      display: flex;
      justify-content: space-evenly;
      flex-direction: column;

      a {
        text-decoration: none;
        color: $off-white;
      }

      a:hover {
        color: $teal;
        transition: 0.3s;
      }
    }

    @media screen and (max-width: 650px) {
      margin-left: 10px;
    }
  }

  @media screen and (max-width: 650px) {
    font-size: 14px;
    width: 38%;
  }
}

.app_footer_contact_container {
  width: 50%;
  display: flex;
  flex-direction: column;

  .footer_contact_items {
    list-style: none;
    padding: 10px;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    height: 200px;

    li {
      padding-bottom: 7px;
    }

    a {
      text-decoration: none;
      color: $white;
    }

    a:hover {
      color: $teal;
      transition: 0.3s;
    }

    li, svg {
      vertical-align: text-top;
      display: inline-block;
      color: $white;
    }

    svg:hover {
      color: $grey;
      transition: 0.3s;
    }

    .design_tag a {
      color: $teal;
    }

    .design_tag a:hover {
        color: $grey;
        transition: 0.3s;
    }

    @media screen and (max-width: 650px) {
      width: 230px;
      margin-left: 30px;
    }
  }

  @media screen and (max-width: 650px) {
    font-size: 14px;
  }
} 

.app_footer_contact_banner {
  display: flex;
  align-items: flex-end;
  width: 100%;
  height: 200px;
  margin-top: 10px;
  justify-content: space-between;
  padding: 20px;

  h1 {
    font-size: 72px;
    color: $white;

    @media screen and (max-width: 650px) {
      font-size: 48px;
    }
  }

  a {
    text-decoration: none;
    color: $white;
  }

  img {
    width: 200px;
    height: 200px;

    // @media screen and (max-width: 650px) {
    //   margin-left: 140px;
    // }
  }


  svg:hover {
    color: $grey;
    transition: 0.3s;
  }

  @media screen and (max-width: 650px) {
    justify-content: flex-start;
    margin-top: 0;
  }
}
