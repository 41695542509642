///Theme///
$white: #f8fafc;
$grey: #D8E3E7;
$aqua: #97CECC;
$teal: #126E82;
$blue: #132C33;

///Earth Tones////
$peach: #D7C0AE;
$cranberry: #451a03;
$olive: #4b5320;
$stone: #a8a29e;
$cream: #EEE3CB;
$brown: #967E76;


///Color Theme///
$red: #C4421A;
$orange: #F66D38;
$peach: #FFE8DE;
$beige: #E8E6D5;
// $aqua: #97CECC;
// $teal1: #12908E;
$green: #16594A;
$black: #04090A;
$grad1: linear-gradient(to left bottom, #f46840, #ba4a3a, #81312e, #491c1e, #190304);
$grad2: linear-gradient(to left bottom, #b3310b, #c03e0f, #cd4a13, #d95718, #e6631c);
$gold: #D4A26B;
$off-white: #ECEBEB;
$dark-grey: #4D4D4D;




///Fonts///
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@200;300;400;600;700&family=Roboto:wght@100;300;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@200;300;400;600;700&family=Poppins:wght@100;200;300;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Italianno&display=swap');


@font-face {
    font-family: Script;
    src: url(../public/fonts/Randela-Smitha.otf);
    font-weight: normal;
}

.Script {
  font-family: Script;
}



$font-cursive: Script, sans-serif;
$font-alt: Poppins, sans-serif;
$font-secondary: Montserrat, sans-serif;
$font-primary: Roboto, sans-serif;


* {
  box-sizing: border-box;
  margin: 0;
  scroll-behavior: smooth;
}
.app_bg {
  position: relative;
  background: url('./assets/bgoverlay.png');
  background-position: center;
  background-size: cover;
  background-repeat: repeat;
  background-attachment: fixed;
  z-index: 1;
}

