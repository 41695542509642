@import '../../index.scss';

*{
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
}

.app_navbar {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  position: fixed;
  background: $grey;
  justify-content: space-evenly;
  z-index: 4;

  .navbar_img {
    padding-right: 100px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 300px;
    
    .nav-text {
      width: auto;
      padding-bottom: 40px;

      p {
        position: absolute;
        font-family: $font-alt;
        font-size: 24px;
        color: $black;
        // margin-top: 10px;
        font-weight: 500;
  
        @media screen and (max-width: 1300px) {
          font-size: 18px;
        }
      }
    }

    img {
      width: 100px;
      height: 100px;
    }
  }

  .navbar_items {
    display: flex;
    list-style: none;
    align-items: center;
    margin-left: 30px;
    
    li {
      display: flex;
      padding: 0px 30px;

      @media screen and (max-width: 2000px) {
        padding: 0px 20px;
      }
    }

    a { 
      text-decoration: none;
      font-family: $font-alt;
      font-size: 20px;
      color: $black;

      @media screen and (min-width: 2000px) {
        font-size: 18px;
      }
      
      @media screen and (max-width: 2000px) {
        font-size: 14px;
      }
      
      @media screen and (max-width: 1300px) {
        font-size: 10px;
      }
    }

    a:hover {
      color: grey;
      transition: 0.5s;
    }

    @media screen and (max-width: 2000px) {
      margin-left: 150px;
    }
    
    @media screen and (max-width: 1500px) {
      margin-left: 60px;
    }
  }

  hr {
    height: 30px;
    margin-top: 35px;
    margin-right: 5px;
    color: black;

    @media screen and (max-width: 2500px) {
      margin-right: 5px;
    }
  }

  .navbar_socials {
    display: flex;
    align-items: center;
    justify-content: space-around;
    
    a {
      color: $black;
      padding-right: 15px;
      padding-left: 15px;

      @media screen and (max-width: 2000px) {
        padding-right: 10px;
        padding-left: 10px;
      }
    }

    a:hover {
      color: grey;
      transition: 0.5s;
    }

    @media screen and (min-width: 2500px) {
      margin-right: 200px;
    }
    
    @media screen and (max-width: 2500px) {
      margin-right: 100px;
    }
    
  }

  @media screen and (max-width: 1200px) {
    display: none;
  }
}

/*************Mobile Device Screens**************/

.app_menu {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: $grey;
  width: 100%;

  .menu_img {
    // margin-right: 200px;
    display: flex;
    flex-direction: row;
    align-items: center;

    
    p {
      position: absolute;
      margin-left: 80px;
      font-family: $font-alt;
      font-size: 25px;
      color: $black;
      padding-left: 20px;

      @media screen and (max-width: 1120px) {
        display: none;
      }
    }

    img {
      width: 100px;
      height: 100px;

      @media screen and (max-width: 900px) {
        width: 120px;
        height: 100px;
      }
    }
  }

  hr {
    display: none;
  }

  ul {
    display: flex;
    flex-direction: column;
    list-style: none;
    align-items: center;
  }

  a { 
    text-decoration: none;
    color: $black;
    font-family: $font-alt;
    font-weight: 100;
  }

  svg {
    width: 50px;
    height: 50px;
    color: $black !important;
    margin-right: 10px;
    
    // @media screen and (max-width: 1150px) {
    //   margin-right: 100px;
    // }

    // @media screen and (max-width: 650px) {
    //   margin-right: 70px;
    //   padding-right: 0;
    // }

  }

  .menu_container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;

    .menu_items {
      padding-right: 0;

      li {
        padding: 15px;
        font-size: 24px;
      }

      a {
        font-weight: 300;
      }
      
      @media screen and (max-width: 650px) {
        padding-top: 30px;
      }
    }
  }  

  .menu_overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: $grey;
    flex-direction: column;
    z-index: 5;

    svg {
      color: $black !important;
    }
  }

  .menu_socials {
    display: flex;
    z-index: 6;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    a {
      padding: 10px;
      margin-top: 30px;

      // @media screen and (max-width: 650px) {
      //   padding-left: 80px;
      // }
    }

    p {
      margin-top: 100px;
      font-family: $font-alt;
      font-weight: 300;
      color: $black;
      font-size: 28px;

      @media screen and (max-width: 650px) {
        margin-top: 50px;
        padding-bottom: 50px;
      }
    }

  }

  .overlay_close {
    top: 0;

    svg {
      color: black;
    }
  }

  .menu_overlay, .overlay_close {
    font-size: 27px;
    cursor: pointer;
    position: absolute;
    // right: -50px;
  }

  @media screen and (min-width: 1200px) {
    display: none;
  }
  
  @media screen and (max-width: 650px) {
    // justify-content: space-between;
  }
}