@import '../../index.scss';

.app__contact_footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
}

.contact_footer_title-container {
  margin-bottom: 50px;
}

.contact_footer-title {
  font-size: 64px;
  font-family: $font-alt;
  font-weight: normal;
}

.app_contact_footer-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: $white;

  img {
    @media screen and (max-width: 450px) {
      width: 200px;
      height: 200px;
    }
  }

  @media screen and (max-width: 1100px) {
    flex-direction: column;
  }
}

.app_contact_footer-logo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.app__footer-form {
  width: 60%;
  flex-direction: column;
  margin: 1rem 2rem;

  div {
      width: 100%;
      margin: 0.75rem 0;
      border-radius: 10px;
      cursor: pointer;
      background-color: $white;
      transition: all 0.3s ease-in-out;
  

  input, textarea {
      width: 100%;
      padding: 0.95rem;
      border: none;
      border-radius: 7px;
      background-color: $white;
      font-family: $font-alt;
      color: $black;
      outline: none;

  }

  textarea {
      height: 170px;
  }

  &:hover {
      box-shadow: 0 0 10px black;
  }
}

  button {
      padding: 1rem 2rem;
      border-radius: 10px;
      border: none;
      background-color: $grey;
      font-weight: 500;
      color: var(--white-color);
      outline: none;
      margin: 2rem 0 0 0;
      font-family: $font-alt;
      width: 100%;
      cursor: pointer;
  }

  @media screen and (max-width: 768px) {
      width: 100%;
      margin: 1rem 0;
  }
}